<template>
  <v-col cols="11" class="mx-auto mb-12">
    <v-row>
      <v-col cols="12" class="text-right">
        <router-link :to="{ name: 'view-batches' }">
          <small class="font-weight-bold black--text"
            >&lt; Back to Batches</small
          >
        </router-link>
      </v-col>
    </v-row>
    <v-row v-if="batch" class="jumbotron p-2">
      <v-col cols="4" class="text-left py-5 pl-6 mr-auto">
        <strong>{{ batch.name }} {{ batch.date_created }}</strong>
      </v-col>
      <v-col cols="4" class="text-right py-4 pr-6">
        <v-btn
          :to="{ name: 'create-claim', params: { batch_id: batch.id } }"
          text
          medium
          color="green darken-4"
          class="text-capitalize white--text"
        >
          <span>Add new claim</span>
        </v-btn>
      </v-col>
    </v-row>
    <!-- show dialog message if batch with id matching url isn't found -->
    <v-row v-else>
      <v-col cols="10" class="mx-auto">
        <h4>Error: Batch with ID: {{ $route.params.batch_id }} not found</h4>
      </v-col>
    </v-row>
    <hr class="px-10 mt-10" />
    <List v-if="batch" :titles="titles" :data="claims" render="clerkClaims" />
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import List from "@/views/apps/claim/ListView.vue";

export default {
  data() {
    return {
      titles: [
        "Patient Name",
        "Age",
        "Identification Number",
        "Date Created",
        "Edit",
        "Delete",
      ],
    };
  },
  components: {
    List,
  },
  computed: {
    ...mapGetters({
      batches: "claimModule/batches",
    }),
    batch() {
      const name = this.batches.find(
        (batch) => batch.id === Number(this.$route.params.batch_id)
      );
      return name;
    },
    claims() {
      return this.batch.claims;
    },
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
</style>
